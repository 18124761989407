const URL = "https://partner-api.bimaguide.com/api/auth/v1";
const OPSURL = "https://partner-api.bimaguide.com/api/ops/v1";
const OQRURL = "https://oqr-api.bimaguide.com/api";
const JARVIS_BASE_URL = "https://jarvis-api.bimaguide.com/api";

export const loginURL = `${URL}/login`;
export const getUserPermissionsURL = `${OQRURL}/bg-user/state/v1/employee-state`;
export const partnerListURL = `${OPSURL}/partner-under-verification/`;
export const partnerPaymentURL = `${OPSURL}/partner-payment-verification/`;
export const partnerNOCURL = `${OPSURL}/partner-noc-verification/`;
export const verifyPartnerDOCURL = `${OPSURL}/verify-partner-documents/`;
export const verifyCheckURL = `${OPSURL}/verify-cancel-cheque/`;
export const verifyMobileURL = `${OPSURL}/send-otp/`;
export const verifyOtpURL = `${OPSURL}/verify-otp/`;
export const verifyPanURL = `${OPSURL}/verify-pan/`;
export const addPartnerURL = `${OPSURL}/referral-code/`;
export const hierarchylistUrl = `${OPSURL}/hierarchy/`;
export const quoteListURL = `${OQRURL}/ops/oqr/v1/list/`;
export const quoteDetailURL = `${OQRURL}/ops/oqr-actions/v1/ops-quote-modal/`;
export const postCommentURL = `${OQRURL}/ops/oqr-actions/v1/ops-comment/`;
export const quoteVerifyDocsURL = `${OQRURL}/ops/oqr-actions/v1/verify-quote-document/`;
export const noQuoteAvailableURL = `${OQRURL}/ops/oqr-actions/v1/close-case/`;
export const shareQuoteDetailsURL = `${OQRURL}/ops/oqr-actions/v1/share-quote-details/`;
export const quoteChangeRequestURL = `${OQRURL}/ops/oqr-actions/v1/change-quote-request/`;
export const shareQuoteApprovalURL = `${OQRURL}/ops/oqr-actions/v1/share-quote-approval/`;
export const paymentLinkPendingURL = `${OQRURL}/ops/oqr-actions/v1/payment-link-shared/`;
export const agencyDetailsURL = `${OQRURL}/ops/oqr-actions/v1/share-inspection-details/`;
export const pendingReportURL = `${OQRURL}/ops/oqr-actions/v1/upload-inspection-report/`;
export const getMappingListURL = `${OQRURL}/ops/oqm/v1/list`;
export const getMappingVerificationDetails = `${OQRURL}/ops/oqm/v1/verify-mapping`;
export const mappingRequestVerifyDocsURL = `${OQRURL}/ops/oqm/v1/verify-mapping`;
export const getMapPolicyFieldsURL = `${OQRURL}/ops/oqm/v1/map-policy-options`;
export const mapPolicyPOSTURL = `${OQRURL}/ops/oqm/v1/map-policy-copy`;
export const editPolicyPOSTURL = `${OQRURL}/ops/oqm/v1/edit-policy-copy`;
export const mapPolicyRejectURL = `${OQRURL}/ops/oqm/v1/reject-policy-mapping`;
export const mapPolicyCancelURL = `${OQRURL}/ops/oqm/v1/cancel-policy-mapping`;
export const paymentLinkVerifyURL = `${OQRURL}/ops/oqr-actions/v1/payment-validate`;
export const uploadPolicyCopyURL = `${OQRURL}/ops/oqr-actions/v1/upload-policy-copy`;
export const verifyKycPOST = `${OQRURL}/ops/oqr-actions/v1/verify-kyc-document`;

//Jarvis URLs
export const getOptionsDataURL = `${JARVIS_BASE_URL}/jarvis/ops-grid/v1/search-options`;
export const getPolicyDealsURL = `${JARVIS_BASE_URL}/jarvis/ops-grid/v1/partner-deals`;

// Sales Dashboard URLs
export const getSalesPartnerURL = `${OQRURL}/sales/partner/v1/list`;
export const getSalesMappingListURL = `${OQRURL}/sales/mapping/v1/list`;
export const getSalesPartnersListURL = `${OQRURL}/sales/partner/v1/ids`;
export const createSalesMappingRequestURL = `${OQRURL}/sales/mapping/v1/create`;

export const getInsurerAndAddOnsListURL = `${OQRURL}/masters/v1/insurer-addons`;
export const raiseQuoteRequestPOST = `${OQRURL}/sales/oqr/v1/create-quote`;
export const getRequiredDocsDataURL = `${OQRURL}/sales/mapping/v1/documents`;
export const uploadSalesDocsPOST = `${OQRURL}/sales/mapping/v1/documents`;
export const getSalesQuoteListURL = `${OQRURL}/sales/oqr/v1/list`;
export const salesBookingList = `${OQRURL}/sales/booking/v1/list`;
export const salesBookingModal = `${OQRURL}/sales/booking/v1/modal`;

//Sales Quote Modal APIs
export const getSalesQuoteDetailURL = `${OQRURL}/sales/oqr-actions/v1/partner-quote-modal`;
export const salesPostCommentURL = `${OQRURL}/sales/oqr-actions/v1/partner-comment/`;
export const getQuoteUploadDocumentsDetailsForm = `${OQRURL}/sales/oqr-actions/v1/documents`;
export const sendFillQuoteDetailsForm = `${OQRURL}/sales/oqr-actions/v1/fill-inspection-details`;
export const getPinCodeCity = `${OQRURL}/masters/v1/pincode-details`;
export const getSalesDashboardData = `${OQRURL}/sales/dashboard/v1/data`;
export const salesPostPaymentProofURL = `${OQRURL}/sales/oqr-actions/v1/partner-payment-proof`;
export const salesPostKycDataURL = `${OQRURL}/sales/oqr-actions/v1/upload-kyc-document`;
export const postDeclineAllQuoteShared = `${OQRURL}/sales/oqr-actions/v1/decline-all-quote`;
export const salesPostDeclineSingleQuoteSharedURL = `${OQRURL}/sales/oqr-actions/v1/decline-quote`;
export const salesPostAcceptSingleQuoteSharedURL = `${OQRURL}/sales/oqr-actions/v1/accept-quote`;
export const salesPostUpdateSingleQuoteSharedURL = `${OQRURL}/sales/oqr-actions/v1/update-quote`;

// sales Manager Dashboard URls
export const getSaleInsuanceManagerList = `${OQRURL}/sales/manager/v1/pre-issuance`;
export const getSalePartnerManagerList = `${OQRURL}/sales/manager/v1/partners`;
export const getSalesManagerBookingList = `${OQRURL}/sales/manager/v1/bookings`;
export const getInsurerIds = `${OQRURL}/masters/v1/insurers`;
