import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Checkbox, IconButton } from "@mui/material";
import { Clear } from "@mui/icons-material";
import { showWarning } from "../../../utils/utils";

export default function DropDown({
  value,
  onChange,
  placeholder,
  data,
  height,
  width,
  name,
  disabled, // Add disabled prop
  renderOption, // Add a custom option rendering function
  multiple = false,
  defaultValue,
  ...rest
}) {
  return (
    <Select
      name={name}
      value={value}
      onChange={onChange}
      // clear button
      endAdornment={
        !(!value || (value && value.length === 0)) && !disabled ? (
          <IconButton
            onClick={() => onChange({ target: { value: "" } })}
            size="small"
          >
            <Clear />
          </IconButton>
        ) : null
      }
      // value is empty or empty array, icon is ExpandMoreIcon else Clear
      IconComponent={
        !value || (value && value.length === 0) ? ExpandMoreIcon : null
      }
      displayEmpty
      disabled={disabled} // Pass the disabled prop to the Select component
      sx={{
        boxSizing: "border-box",
        boxShadow: "none",
        "& .MuiOutlinedInput-notchedOutline": {
          border: showWarning(value, defaultValue)
            ? "1px solid #FFA500"
            : "1px solid #ccc",
          borderRadius: 2,
        },
        "&.Mui-error .MuiOutlinedInput-notchedOutline": {
          borderColor: "#f44336",
          borderWidth: "1px",
        },
        "& .MuiFormHelperText-root": {
          width: "100%",
          marginTop: "0.25rem",
          fontSize: "12px !important",
          color: "#dc3545",
          height: "0.9em",
          fontWeight: "400 !important",
        },
        height: height ? height : 40,
        fontSize: 14,
        minWidth: "160px",
        color: showWarning(value, defaultValue) ? "#FFA500" : "#8B8B8B",
        fontWeight: 400,
        width: width ? width : "100%",
        background: "#fff",
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: "#0691fc",
        },
      }}
      multiple={multiple}
      renderValue={(selected) => {
        if (!selected || selected.length === 0) {
          return <span>{placeholder}</span>;
        }

        if (multiple) {
          if (selected.length === 1) {
            return selected[0];
          }
          if (selected.length === data.length) {
            return "All";
          }
          if (selected.length > 1) {
            return `${selected[0]} + ${selected.length - 1} more`;
          }
        }
        return selected;
      }}
      error={!!rest.error}
      {...rest}
    >
      {data?.map((item, idx) => (
        <MenuItem sx={{ fontSize: 14 }} key={item + "-" + idx} value={item}>
          {multiple && <Checkbox checked={value?.indexOf(item) > -1} />}
          {/* Use the custom renderOption function if provided, else display the item */}
          {renderOption ? renderOption(item) : item}
        </MenuItem>
      ))}
    </Select>
  );
}
