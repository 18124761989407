import {
  GET_MANAGER_PARTNER_LIST_SUCCESS,
  GET_MANAGER_PARTNER_LIST_FAILURE,
  MANAGER_PARTNER_DATA_LOADING,
  MANAGER_PARTNER_RESET_PAGINATION,
} from "../action/salesManagerPartner";

const initialState = {
  salesManagerPartnersList: [],
  hierarchyList: [],
  hierarchy: "",
  pagination: { curPage: 1, totalPage: 0 },
  loading: true,
  error: null,
};

export const salesManagerPartnerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MANAGER_PARTNER_LIST_SUCCESS:
      return {
        ...state,
        salesManagerPartnersList: action.payload.salesManagerPartnersList || [],
        hierarchyList: action.payload.hierarchyList,
        hierarchy: action.payload.hierarchy,
        pagination: {
          ...state.pagination,
          ...action.payload.pagination,
        },
        loading: false,
        error: null,
      };
    case GET_MANAGER_PARTNER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case MANAGER_PARTNER_DATA_LOADING:
      return {
        ...state,
        loading: action.data,
      };
    case MANAGER_PARTNER_RESET_PAGINATION:
      return {
        ...state,
        pagination: {
          curPage: 1,
          totalPage: 0,
        },
      };

    default:
      return state;
  }
};
