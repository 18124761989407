import React, { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import MenuItem from "@mui/material/MenuItem";
import { InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { showWarning } from "../../../utils/utils";

export default function AutoComplete({
  value,
  onChange,
  placeholder = "Search",
  data,
  width,
  height,
  name,
  disabled,
  renderOption,
  error = false,
  isClearable = true,
  clearOnBlur = true,
  defaultValue,
  ...rest
}) {
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState(data);

  useEffect(() => {
    if (search === "" || search.length <= 2) {
      setFilteredData(data);
    } else {
      setFilteredData(
        data.filter((item) =>
          typeof item === "string"
            ? item.toLowerCase().includes(search.toLowerCase())
            : item.label.toLowerCase().includes(search.toLowerCase()),
        ),
      );
    }
  }, [search, data]);

  return (
    <Autocomplete
      value={value}
      name={name}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      inputValue={search}
      onInputChange={(event, newInputValue) => {
        setSearch(newInputValue);
      }}
      options={filteredData}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.label || ""
      }
      renderInput={(params) => (
        <TextField
          error={error}
          {...params}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ mt: -0.2 }} />
              </InputAdornment>
            ),
          }}
        />
      )}
      renderOption={(props, option) => (
        <MenuItem {...props}>
          {renderOption
            ? renderOption(option)
            : typeof option === "string"
              ? option
              : option.label}
        </MenuItem>
      )}
      noOptionsText={
        search.length > 2 ? "No matching results found" : "Type to search"
      }
      sx={{
        width: width || "100%",
        height: height || "40px",
        borderRadius: "8px",
        backgroundColor: "#fff",
        border: "none",
        "&:hover": { borderColor: "#8B8B8B" },
        "& .MuiOutlinedInput-root": {
          borderRadius: "8px",
          backgroundColor: "#fff",
          border: showWarning(value, defaultValue)
            ? "1px solid #FFA500"
            : "1px solid #8B8B8B",
          padding: "0px 0px 0px 10px",
          boxShadow: "none",
          "&:hover": { borderColor: "#8B8B8B" },
          "& fieldset": {
            border: "none",
          },
          "&:hover fieldset": {
            border: "none",
          },
          "&.Mui-focused fieldset": {
            border: "none",
          },
          // error border
          "&.Mui-error": {
            "&.MuiOutlinedInput-root": {
              borderColor: "#f44336",
              borderWidth: 1,
            },
          },
        },
        "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input	": {
          color: showWarning(value, defaultValue)
            ? "#FFA500 ! important"
            : "#8B8B8B",
        },
      }}
      disabled={disabled}
      isClearable={isClearable} // if you want to clear the input on blur
      clearOnBlur={clearOnBlur}
      error={error}
      {...rest}
    />
  );
}
