import { TextField, Typography, InputAdornment } from "@mui/material";
import React from "react";
import { showWarning } from "../../../utils/utils";

function TextInput({
  label,
  isMandatory,
  icon,
  iconPosition = "start",
  type,
  handleChange,
  size = "small",
  value,
  defaultValue,
  ...rest
}) {
  return (
    <>
      {/* Label with optional mandatory indicator */}
      <Typography variant="caption">
        {label} {isMandatory && "*"}
      </Typography>

      {/* Material-UI TextField */}
      <TextField
        variant="outlined"
        fullWidth
        sx={{
          backgroundColor: "#fff",
          "& .MuiOutlinedInput-notchedOutline": {
            border: showWarning(value, defaultValue)
              ? "1px solid #FFA500"
              : "1px solid rgb(209 209 209)",
            borderRadius: 2,
          },
          "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #0691fc",
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              border: "1px solid #0691fc",
            },
          "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
            {
              border: "1px solid #F44336",
            },
          "& .MuiInputBase-input::placeholder": {
            color: "rgb(130, 130, 130)",
            opacity: 1,
            fontWeight: "500",
          },
          "& .MuiInputBase-input": {
            color: showWarning(value, defaultValue)
              ? "#FFA500 ! important"
              : "#8b8b8b",
          },
          borderRadius: 2,
        }}
        InputProps={{
          style: {
            fontWeight: "500",
          },
          startAdornment:
            icon && iconPosition === "start" ? (
              <InputAdornment
                position="start"
                sx={{ width: "30px", height: "30px" }}
              >
                <img
                  src={icon}
                  alt="icon"
                  style={{ width: "50px", height: "50px" }}
                />
              </InputAdornment>
            ) : null,
          endAdornment:
            icon && iconPosition === "end" ? (
              <InputAdornment
                position="end"
                sx={{ width: "30px", height: "30px" }}
              >
                <img
                  src={icon}
                  alt="icon"
                  style={{ width: "26px", height: "26px" }}
                />
              </InputAdornment>
            ) : null,
          disableUnderline: true,
        }}
        size={size}
        type={type}
        onWheel={(e) => type === "number" && e.target.blur()} // Prevent scroll change
        onKeyDown={(e) => {
          if (
            type === "number" &&
            (e.key === "ArrowUp" || e.key === "ArrowDown")
          ) {
            e.preventDefault(); // Prevent arrow key change
          }
        }}
        onChange={handleChange}
        value={value}
        {...rest} // Spread the rest of the props (like value, error, etc.)
      />
    </>
  );
}

export default TextInput;
