import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  Stack,
  Typography,
  Button,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  editPolicyCopyAPI,
  getMappingPolicyOptionsAPI,
  mapPolicyCancelAPI,
  mapPolicyCopyAPI,
  mapPolicyRejectAPI,
} from "../../store/action/mappingRequests";
import Heading from "../generic/Heading/Heading";
import TextInput from "../generic/Input";
import DropDown from "../generic/DropDown/DropDown";
import MapPolicyHeader from "../generic/MapPolicyHeader";
import VerifyDocument from "../generic/verifyDocument";
import { CheckCircle } from "@mui/icons-material";
import SuccessModal from "../generic/Modal";
import { CircularProgress } from "@mui/material";
import DialogBox from "../generic/DialogBox";
import AutoComplete from "../generic/AutoComplete/AutoComplete";
import DatePicker from "../generic/DatePicker/DatePicker";
import { mappingStates } from "../../helpers/enums";
const inputFieldType = Object.freeze({
  TEXTBOX: "TEXTBOX",
  LIST: "LIST",
  MULTI_LIST: "MULTI_LIST",
  BOOLEANBOX: "BOOLEANBOX",
  DATE: "DATE",
  NUMBER: "NUMBER",
  SEARCH_LIST: "SEARCH_LIST",
});

const inputHandler = (
  type,
  fieldName,
  value,
  onValueChange,
  optionsList,
  isMandatory,
  apiName,
  error,
  defaultValue = "",
) => {
  switch (type) {
    case inputFieldType.TEXTBOX:
      return (
        <TextInput
          label={fieldName}
          value={value}
          onChange={(e) => onValueChange(apiName, e.target.value)}
          error={error}
          isMandatory={isMandatory}
          defaultValue={defaultValue}
        />
      );
    case inputFieldType.LIST:
      return (
        <Box flexDirection={"column"}>
          <Typography variant="caption">
            {fieldName}
            {isMandatory && "*"}
          </Typography>
          <DropDown
            placeholder={fieldName}
            value={value || ""}
            onChange={(e) => onValueChange(apiName, e.target.value)}
            data={optionsList}
            error={error}
            defaultValue={defaultValue}
          />
        </Box>
      );
    case inputFieldType.MULTI_LIST:
      return (
        <Box flexDirection={"column"}>
          <Typography variant="caption">
            {fieldName}
            {isMandatory && "*"}
          </Typography>
          <DropDown
            multiple
            placeholder={fieldName}
            value={value || []}
            onChange={(e) => onValueChange(apiName, e.target.value)}
            data={optionsList}
            error={error}
            defaultValue={defaultValue}
          />
        </Box>
      );

    case inputFieldType.SEARCH_LIST:
      return (
        <Box flexDirection={"column"}>
          <Typography variant="caption">
            {fieldName}
            {isMandatory && "*"}
          </Typography>
          <AutoComplete
            placeholder={fieldName}
            value={value || ""}
            onChange={(selectedOptions) =>
              onValueChange(apiName, selectedOptions)
            }
            data={optionsList}
            error={error}
            defaultValue={defaultValue}
          />
        </Box>
      );

    case inputFieldType.BOOLEANBOX:
      return (
        <Box flexDirection={"column"}>
          <Typography variant="caption">
            {fieldName}
            {isMandatory && "*"}
          </Typography>
          <DropDown
            placeholder={fieldName}
            value={value === true ? "Yes" : value === false ? "No" : ""}
            onChange={(e) =>
              onValueChange(apiName, e.target.value, inputFieldType.BOOLEANBOX)
            }
            data={["Yes", "No"]}
            error={error}
            defaultValue={
              defaultValue === true ? "Yes" : defaultValue === false ? "No" : ""
            }
          />
        </Box>
      );
    case inputFieldType.DATE:
      return (
        <Box flexDirection={"column"}>
          <Typography variant="caption">
            {fieldName}
            {isMandatory && "*"}
          </Typography>
          <div className={` ${error ? "date-picker-error" : ""}`}>
            <DatePicker
              options={{
                dateFormat: "d-m-Y",
                allowInput: true,
                disableMobile: true,
                // defaultDate: value,
              }}
              style={{ width: "100%" }}
              value={value}
              onChange={(date) => onValueChange(apiName, date[0])}
              defaultValue={defaultValue}
            />
          </div>
        </Box>
      );
    case inputFieldType.NUMBER:
      return (
        <TextInput
          type="number"
          // inputMode="numeric"
          label={fieldName}
          value={value}
          onChange={(e) =>
            onValueChange(apiName, e.target.value, inputFieldType.NUMBER)
          }
          error={error}
          isMandatory={isMandatory}
          defaultValue={defaultValue}
        />
      );
    default:
      return (
        <TextInput
          label={fieldName}
          value={value}
          onChange={(e) => onValueChange(apiName, e.target.value)}
          error={error}
          defaultValue={defaultValue}
        />
      );
  }
};

const MapPolicyModal = ({ mappingId, handleClose, open }) => {
  const [bookingDetails, setBookingDetails] = useState({});
  const [vehicleDetails, setVehicleDetails] = useState({});

  const [openSuccess, setOpenSuccess] = useState(false);
  const [errors, setErrors] = useState({});
  const [reject, setReject] = useState(false);
  const [reason, setReason] = useState("");
  const [reasonError, setReasonError] = useState(false);
  const {
    bookingOptions,
    vehicleOptions,
    isLoadingDetails,
    headerOptions,
    mappingState,
    docs,
  } = useSelector((state) => state.mappingRequests);
  const dispatch = useDispatch();
  useEffect(() => {
    if (mappingId) {
      dispatch(getMappingPolicyOptionsAPI(mappingId));
    }
  }, [dispatch, mappingId]);

  useEffect(() => {
    if (bookingOptions.length > 0) {
      const bookingDetails = {};
      // bookingOptions.forEach((option) => {
      for (let option of bookingOptions) {
        bookingDetails[option.apiField] = {
          value: option.default,
          error: false,
          isMandatory: option.isMandatory,
        };
      }
      setBookingDetails(bookingDetails);
    }
    if (vehicleOptions.length > 0) {
      const vehicleDetails = {};
      // vehicleOptions.forEach((option) => {
      for (let option of vehicleOptions) {
        vehicleDetails[option.apiField] = {
          value: option.default,
          error: false,
          isMandatory: option.isMandatory,
        };
      }
      setVehicleDetails(vehicleDetails);
    }
  }, [bookingOptions, vehicleOptions, headerOptions]);
  const onBookingDetailsChange = (apiName, value, fieldType) => {
    if (apiName == "policyEndDate") {
      if (bookingDetails["policyStartDate"].value > value) {
        setBookingDetails({
          ...bookingDetails,
          [apiName]: { ...bookingDetails[apiName], value: value, error: true },
        });
      } else {
        setBookingDetails({
          ...bookingDetails,
          [apiName]: { ...bookingDetails[apiName], value: value },
        });
      }
    } else if (apiName == "policyStartDate") {
      if (bookingDetails["policyEndDate"].value < value) {
        setBookingDetails({
          ...bookingDetails,
          [apiName]: { ...bookingDetails[apiName], value: value, error: true },
        });
      } else {
        setBookingDetails({
          ...bookingDetails,
          [apiName]: { ...bookingDetails[apiName], value: value },
        });
      }
    } else {
      if (fieldType == inputFieldType.BOOLEANBOX) {
        value = value == "Yes" ? true : value == "No" ? false : null;
      }
      if (fieldType == inputFieldType.NUMBER) {
        if (value) {
          value = parseInt(value);
        }
      }
      setBookingDetails({
        ...bookingDetails,
        [apiName]: { ...bookingDetails[apiName], value: value },
      });

      if (value) {
        setErrors({ ...errors, [apiName]: false });
      }
    }
  };
  const onVehicleDetailsChange = (apiName, value, fieldType) => {
    if (fieldType == inputFieldType.BOOLEANBOX) {
      value = value == "Yes" ? true : value == "No" ? false : null;
    }
    if (fieldType == inputFieldType.NUMBER) {
      if (value) {
        value = parseInt(value);
      }
    }
    setVehicleDetails({
      ...vehicleDetails,
      [apiName]: { ...vehicleDetails[apiName], value: value },
    });

    if (value) {
      setErrors({ ...errors, [apiName]: false });
    }
  };
  const handleSubmit = () => {
    let isBookingDetailsValid = true;
    let isVehicleDetailsValid = true;
    let errorList = {};
    for (let key in bookingDetails) {
      if (
        bookingDetails[key].isMandatory &&
        (bookingDetails[key].value === undefined ||
          bookingDetails[key].value === null ||
          bookingDetails[key].value === "")
      ) {
        isBookingDetailsValid = false;
        errorList[key] = true;
      }
    }
    for (let key in vehicleDetails) {
      if (
        vehicleDetails[key].isMandatory &&
        (vehicleDetails[key].value === undefined ||
          vehicleDetails[key].value === null ||
          vehicleDetails[key].value === "")
      ) {
        isVehicleDetailsValid = false;
        errorList[key] = true;
      }
    }
    if (isBookingDetailsValid && isVehicleDetailsValid) {
      let data = { mappingId: mappingId };
      for (let key in bookingDetails) {
        if (bookingDetails[key].value !== "")
          data[key] = bookingDetails[key].value;
      }
      for (let key in vehicleDetails) {
        if (vehicleDetails[key].value !== "")
          data[key] = vehicleDetails[key].value;
      }
      if (mappingState === mappingStates.POLICY_MAPPING_COMPLETED) {
        dispatch(editPolicyCopyAPI(data, setOpenSuccess)); // Call Edit API
      } else {
        dispatch(mapPolicyCopyAPI(data, setOpenSuccess)); // Call Map API
      }
    } else {
      setErrors(errorList);
    }
    // setOpenSuccess(true);
  };
  const handleReject = () => {
    setReject(true);
  };
  const handleRejectConfirm = () => {
    if (reason === "") {
      setReasonError(true);
      return;
    }

    let data = {
      mappingId: mappingId,
      rejectReason: reason,
      cancelReason: reason,
    };
    if (mappingState === mappingStates.POLICY_MAPPING_COMPLETED) {
      dispatch(mapPolicyCancelAPI(data, setOpenSuccess));
    } else {
      dispatch(mapPolicyRejectAPI(data, setOpenSuccess));
    }
    setReject(false);
  };

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleConfirm = () => {
    handleSubmit();
    handleCloseModal();
  };

  return (
    <>
      <Dialog
        PaperProps={openSuccess && { sx: { borderRadius: "32px" } }}
        fullScreen={!openSuccess}
        open={open}
      >
        {/* <DialogTitle>Map Policy</DialogTitle> */}
        {openSuccess ? (
          <Box
            borderRadius={4}
            // borderRadius={"2rem"}

            width={"100%"}
            height={"100%"}
            padding={"2rem"}
          >
            <SuccessModal
              title={
                !reason
                  ? mappingState === mappingStates.POLICY_MAPPING_COMPLETED
                    ? "Edit Policy Successfully"
                    : "Policy Mapped Successfully"
                  : "Docs Invalidated Successfully"
              }
              // subTitle={"Policy has been Mapped successfully"}
              shareButton={"All Requests"}
              btnDisabled={false}
              onClick={() => {
                setOpenSuccess(false);
                handleClose();
              }}
            />
          </Box>
        ) : isLoadingDetails ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <div>
            <MapPolicyHeader
              headerOptions={headerOptions}
              handleClose={handleClose}
            />
            <DialogContent
              style={{ backgroundColor: "var(--color-grey-light)" }}
            >
              <Stack direction="row" gap={2}>
                <Box alignItems={"center"} direction="column" maxWidth={"50%"}>
                  <Heading
                    style={{ marginBottom: "2rem" }}
                    type={"withborder small"}
                    text={"Booking Details"}
                  />
                  <Box
                    sx={{
                      padding: "1rem",
                      backgroundColor: "var(--color-white)",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                      borderRadius: "var(--border-radius-8)",
                    }}
                  >
                    <Grid container columns={2} spacing={2}>
                      {bookingOptions &&
                        bookingOptions.length > 0 &&
                        bookingOptions.map((option, index) => (
                          // 2 columns per row
                          <Grid item xs={1} key={`booking-option-${index}`}>
                            {inputHandler(
                              option.fieldType,
                              option.fieldName,
                              bookingDetails[option.apiField]?.value,
                              onBookingDetailsChange,
                              option.options,
                              option.isMandatory,
                              option.apiField,
                              errors[option.apiField] || false,
                              option.default,
                            )}
                          </Grid>
                        ))}
                    </Grid>
                  </Box>
                </Box>
                <Box
                  direction="column"
                  flexDirection={"column"}
                  display={"flex"}
                  maxWidth={"50%"}
                  justifyContent={"space-between"}
                >
                  <Box direction="column" sx={{ width: "100%" }}>
                    <Box direction="column" sx={{ width: "100%" }}>
                      <Heading
                        style={{ marginBottom: "2rem" }}
                        type={"withborder small"}
                        text={"Vehicle Details"}
                      />
                      <Box
                        sx={{
                          padding: "1rem",
                          backgroundColor: "var(--color-white)",
                          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                          borderRadius: "var(--border-radius-8)",
                        }}
                      >
                        <Grid container columns={2} spacing={2}>
                          {vehicleOptions &&
                            vehicleOptions.length > 0 &&
                            vehicleOptions.map((option, index) => (
                              <Grid
                                item
                                xs={1}
                                key={`vehicle-option-${index}s`}
                              >
                                {inputHandler(
                                  option.fieldType,
                                  option.fieldName,
                                  vehicleDetails[option.apiField]?.value,
                                  onVehicleDetailsChange,
                                  option.options,
                                  option.isMandatory,
                                  option.apiField,
                                  errors[option.apiField] || false,
                                  option.default,
                                )}
                              </Grid>
                            ))}
                        </Grid>
                      </Box>
                    </Box>
                    <Box direction="column" sx={{ width: "100%" }}>
                      <Heading type={"withborder small"} text={"Documents"} />
                      <Box
                        display={"flex"}
                        flexDirection={"column"}
                        padding={"1rem"}
                        gap={"1rem"}
                        marginY={"1rem"}
                        height={"min-content"}
                        fullWidth
                        sx={{
                          // margin: "1rem",
                          padding: "1rem",
                          flexGrow: 1,
                          backgroundColor: "var(--color-white)",
                          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                          // border: "1px solid var(--color-grey)",
                          borderRadius: "var(--border-radius-8)",
                        }}
                      >
                        {docs &&
                          docs.map((i, index) => (
                            <VerifyDocument
                              key={`verify-doc-${index}`}
                              data={i}
                              documentType={i?.documentType}
                            />
                          ))}
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    sx={{ marginTop: "1rem" }}
                  >
                    <Button
                      onClick={handleReject}
                      style={{
                        backgroundColor: "var(--color-red)",
                        textTransform: "none",

                        color: "#fff",
                        borderRadius: "8px",
                        fontSize: "14px",
                        fontWeight: 600,
                        width: "120px",
                        height: "45px",
                      }}
                    >
                      {mappingState === mappingStates.POLICY_MAPPING_COMPLETED
                        ? "Cancel Policy"
                        : "Reject"}
                    </Button>
                    <Button
                      startIcon={<CheckCircle />}
                      onClick={handleOpenModal}
                      sx={{ paddingX: "1rem" }}
                      style={{
                        backgroundColor: "#0691FC",
                        textTransform: "none",

                        color: "#fff",
                        borderRadius: "8px",
                        fontSize: "14px",
                        fontWeight: 600,
                        // width: "116px",
                        height: "45px",
                      }}
                    >
                      {mappingState === mappingStates.POLICY_MAPPING_COMPLETED
                        ? "Edit Policy"
                        : "Map Policy"}{" "}
                    </Button>
                    {/* Confirmation Modal */}
                    <DialogBox open={openModal} setOpen={handleCloseModal}>
                      <DialogTitle>Confirmation</DialogTitle>
                      <DialogContent>
                        <Typography>
                          Are you sure you want to{" "}
                          {mappingState ===
                          mappingStates.POLICY_MAPPING_COMPLETED
                            ? "Edit this policy"
                            : "Map this policy"}
                          ?
                        </Typography>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={handleCloseModal}
                          style={{
                            backgroundColor: "var(--color-red)",
                            textTransform: "none",

                            color: "#fff",
                            borderRadius: "8px",
                            fontSize: "14px",
                            fontWeight: 600,
                            width: "120px",
                            height: "45px",
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={handleConfirm}
                          style={{
                            backgroundColor: "#0691FC",
                            color: "#fff",
                            textTransform: "none",
                            borderRadius: "8px",
                            width: "120px",
                            height: "45px",
                          }}
                        >
                          Confirm
                        </Button>
                      </DialogActions>
                    </DialogBox>
                  </Box>
                </Box>
              </Stack>
            </DialogContent>
          </div>
        )}
      </Dialog>
      {reject && (
        <DialogBox
          width={"451px"}
          open={reject}
          setOpen={() => {
            setReject(false);
            setReason("");
            setReasonError(false);
          }}
          children={
            <Box
              marginX={"auto"}
              gap={"1rem"}
              alignItems={"center"}
              width={"60%"}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              height={"100%"}
            >
              <Box
                display={"flex"}
                flexDirection="column"
                sx={{ marginTop: "1rem" }}
                width={"100%"}
              >
                <DropDown
                  height={"44px"}
                  placeholder={"Select Reason"}
                  onChange={(e) => {
                    setReason(e.target.value);
                    setReasonError(false);
                  }}
                  value={reason || ""}
                  data={[
                    "Document not clear",
                    "Invalid Document",
                    "Policy Cancel",
                  ]}
                  error={reasonError}
                />
                {reasonError && (
                  <Typography className="error_class">
                    Reason is required.
                  </Typography>
                )}
              </Box>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                sx={{ marginTop: "1rem" }}
              >
                <Button
                  onClick={handleRejectConfirm}
                  sx={{ paddingX: "1rem" }}
                  style={{
                    backgroundColor: "var(--color-red)",
                    textTransform: "none",

                    color: "#fff",
                    borderRadius: "8px",
                    fontSize: "14px",
                    fontWeight: 600,
                    width: "120px",
                    height: "45px",
                  }}
                >
                  Reject
                </Button>
              </Box>
            </Box>
          }
          title={""}
        />
      )}
    </>
  );
};

export default MapPolicyModal;
