import React, { forwardRef } from "react";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css"; // Include Flatpickr theme
import "flatpickr/dist/plugins/monthSelect/style.css"; // Import plugin styles
import CalendarTodayIcon from "@mui/icons-material/CalendarToday"; // Material UI icon
import { showDateWarning } from "../../../utils/utils";

const DatePicker = forwardRef(
  (
    {
      placeholder = "Select Date",
      options = {},
      value = "",
      onChange = () => {},
      className,
      style = {},
      maxDate = "",
      error = false,
      defaultValue,
      ...props
    },
    ref,
  ) => {

    return (
      <div
        style={{ position: "relative", display: "inline-flex", width: "100%" }}
      >
        <Flatpickr
          ref={ref}
          placeholder={placeholder}
          options={{
            dateFormat: "d-m-Y",
            maxDate: maxDate,
            ...options,
          }}
          className={
            "date-picker " +
            (className ? className : "") +
            (error ? " date-picker-error" : "") +
            (showDateWarning(value, defaultValue) ? " warning" : "")
          }
          value={value}
          onChange={onChange}
          style={style}
          {...props}
        />
        <CalendarTodayIcon
          style={{
            position: "absolute",
            right: "5%",
            top: "50%",
            transform: "translateY(-50%)",
            pointerEvents: "none", // Prevent icon from interfering with input focus
            color: "#0090FF",
          }}
        />
      </div>
    );
  },
);
DatePicker.displayName = "DatePicker";

export default DatePicker;
