import { ReactComponent as CarNew } from "../assets/images/NewQuoteRequest/Car.svg";
import { ReactComponent as Bike } from "../assets/images/NewQuoteRequest/Bike.svg";
import { ReactComponent as GCV } from "../assets/images/NewQuoteRequest/GCV.svg";
import { ReactComponent as PCV } from "../assets/images/NewQuoteRequest/PCV.svg";
import { ReactComponent as Bus } from "../assets/images/NewQuoteRequest/Bus.svg";
import { ReactComponent as MiscD } from "../assets/images/NewQuoteRequest/MiscD.svg";
const quoteStatusList = {
  ACCEPTED_WITH_INSPECTION: "ACCEPTED_WITH_INSPECTION",
  ACCEPTED: "ACCEPTED",
  UNAVAILABLE: "UNAVAILABLE",
  CHANGE_REQUESTED: "CHANGE_REQUESTED",
  REQUESTED: "REQUESTED",
  DECLINED: "DECLINED",
  SHARED: "SHARED",
};

const quoteRequestStateList = {
  DOCUMENT_VERIFICATION_STARTED: "DOCUMENT_VERIFICATION_STARTED",
  SHARE_QUOTE: "SHARE_QUOTE",
  QUOTE_SHARED: "QUOTE_SHARED",
  DOCUMENT_REUPLOAD: "DOCUMENT_REUPLOAD",
  QUOTE_CHANGE_REQUEST: "QUOTE_CHANGE_REQUEST",
  QUOTE_ACCEPTED_SEND_FOR_APPROVAL: "QUOTE_ACCEPTED_SEND_FOR_APPROVAL",
  QUOTE_ACCEPTED_WITH_INSPECTION_SEND_FOR_APPROVAL:
    "QUOTE_ACCEPTED_WITH_INSPECTION_SEND_FOR_APPROVAL",
  PAYMENT_LINK_PENDING: "PAYMENT_LINK_PENDING",
  PAYMENT_LINK_SHARED: "PAYMENT_LINK_SHARED",
  PAYMENT_PROOF_TAKEN: "PAYMENT_PROOF_TAKEN",
  PAYMENT_DETAILS_APPROVED: "PAYMENT_DETAILS_APPROVED",
  INSPECTION_PORTAL_REQUEST_RAISE: "INSPECTION_PORTAL_REQUEST_RAISE",
  INSPECTION_DETAILS_SHARED: "INSPECTION_DETAILS_SHARED",
  FILL_CUSTOMER_DETAILS: "FILL_CUSTOMER_DETAILS",
  UPLOAD_POLICY_COPY: "UPLOAD_POLICY_COPY",
  FILL_KYC_DOCUMENT: "FILL_KYC_DOCUMENT",
  VERIFY_KYC_DOCUMENT: "VERIFY_KYC_DOCUMENT",
  CASE_CLOSED: "CASE_CLOSED",
};

export const declineReasonOptions = [
  { value: "Already Insured", label: "Already Insured" },
  { value: "High Premium", label: "High Premium" },
  { value: "Coverage Not Sufficient", label: "Coverage Not Sufficient" },
  { value: "Others", label: "Others" },
];

const routePathMap = {
  ADD_PARTNER: "app/partners/add-new-partner",
  OPS_MAPPING_REQUEST: "app/mapping-requests",
  OPS_QUOTE_REQUEST: "app/quote-request",
  PARTNER_VERIFICATION: "app/partners/verification",
  GRID: "app/grid",
};

// Quote request constants
export const newQuoteRequestHeader = [
  { id: 0, key: "CAR", image: CarNew, name: "Car" },
  { id: 1, key: "TW", image: Bike, name: "Two wheeler" },
  { id: 2, key: "GCV", image: GCV, name: "GCV" },
  { id: 3, key: "PCV", image: PCV, name: "PCV" },
  { id: 4, key: "BUS", image: Bus, name: "Bus" },
  { id: 5, key: "MISC_D", image: MiscD, name: "Misc D" },
];

export const productTypeImageObj = {
  CAR: <CarNew />,
  TW: <Bike />,
  GCV: <GCV />,
  PCV: <PCV />,
  BUS: <Bus />,
  MISC_D: <MiscD />,
};
export const mappingStatesBGColor = Object.freeze({
  DOCUMENT_VERIFICATION_STARTED: "#FFBF1A",
  DOCUMENT_REUPLOAD: "#0691FC",
  POLICY_DATA_PROCESSED: "#FFBF1A",
  POLICY_MAPPING_COMPLETED: "#09CE1D",
  POLICY_MAPPING_REJECTED: "#FF3E78",
  POLICY_CANCELLED: "#E73535",
});
export const mappingStates = Object.freeze({
  DOCUMENT_VERIFICATION_STARTED: "DOCUMENT_VERIFICATION_STARTED",
  DOCUMENT_REUPLOAD: "DOCUMENT_REUPLOAD",
  POLICY_DATA_PROCESSED: "POLICY_DATA_PROCESSED",
  POLICY_MAPPING_REJECTED: "POLICY_MAPPING_REJECTED",
  POLICY_MAPPING_COMPLETED: "POLICY_MAPPING_COMPLETED",
});

export const quoteStatesBGColor = Object.freeze({
  QUOTE_REQUESTED: "#0691FC",
  PAYMENT_LINK_SHARED: "#0691FC",
  QUOTE_SHARED: "#0691FC",
  CASE_CLOSED: "#09CE1D",
  QUOTE_ACCEPTED: "#09CE1D",
  PAYMENT_DETAILS_APPROVED: "#09CE1D",
  FILL_CUSTOMER_DETAILS: "#E73535",
  INSPECTION_PORTAL_REQUEST_RAISE: "#E73535",
  INSPECTION_PORTAL_REQUESTED: "#E73535",
  DOCUMENT_REUPLOAD: "#E73535",
  DOCUMENT_VERIFICATION: "#FFBF1A",
});

export { quoteStatusList, quoteRequestStateList, routePathMap };
