import { message } from "../../components/generic/SnakeAlert/SnakeAlert";
import { getSalePartnerManagerList } from "../api";
import axios from "../api/axios";

export const GET_MANAGER_PARTNER_LIST_SUCCESS =
  "GET_MANAGER_PARTNER_LIST_SUCCESS";
export const GET_MANAGER_PARTNER_LIST_FAILURE =
  "GET_MANAGER_PARTNER_LIST_FAILURE";
export const MANAGER_PARTNER_DATA_LOADING = "MANAGER_PARTNER_DATA_LOADING";
export const MANAGER_PARTNER_RESET_PAGINATION =
  "MANAGER_PARTNER_RESET_PAGINATION";

// Action creator to fetch the manager list
export const salesManagerPartnerListAction = (search) => async (dispatch) => {
  try {
    dispatch({ type: MANAGER_PARTNER_DATA_LOADING, data: true });

    const response = await axios.get(getSalePartnerManagerList, {
      params: search,
    });

    if (response.status === 200) {
      const { salesManagerPartnersList, pagination, hierarchyList, hierarchy } =
        response.data.data;

      dispatch({
        type: GET_MANAGER_PARTNER_LIST_SUCCESS,
        payload: {
          salesManagerPartnersList,
          pagination,
          hierarchyList,
          hierarchy,
        },
      });
    } else {
      throw new Error(response.data.msg || "Failed to fetch booking list");
    }
  } catch (error) {
    dispatch({
      type: GET_MANAGER_PARTNER_LIST_FAILURE,
      error: error.message,
    });
    message.error("Error fetching list: " + error.message);
  } finally {
    dispatch({ type: MANAGER_PARTNER_DATA_LOADING, data: false });
  }
};

