import {
  GET_MANAGER_PRE_ISSUANCE_LIST_SUCCESS,
  GET_MANAGER_PRE_ISSUANCE_LIST_FAILURE,
  MANAGER_PRE_ISSUANCE_LIST_DATA_LOADING,
  MANAGER_PRE_ISSUANCE_RESET_PAGINATION,
} from "../action/salesManagerPreIssuance";

const initialState = {
  preIssuanceList: [],
  hierarchyList: [],
  hierarchy: "",
  pagination: { curPage: 1, totalPage: 0 },
  loading: true,
  error: null,
};

export const salesManagerPreIssuanceReducer = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case GET_MANAGER_PRE_ISSUANCE_LIST_SUCCESS:
      return {
        ...state,
        hierarchyList: action.payload.hierarchyList,
        hierarchy: action.payload.hierarchy,
        preIssuanceList: action.payload.preIssuanceList || [],
        pagination: {
          ...state.pagination,
          ...action.payload.pagination,
        },
        loading: false,
        error: null,
      };
    case GET_MANAGER_PRE_ISSUANCE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case MANAGER_PRE_ISSUANCE_LIST_DATA_LOADING:
      return {
        ...state,
        loading: action.data,
      };
    case MANAGER_PRE_ISSUANCE_RESET_PAGINATION:
      return {
        ...state,
        pagination: {
          curPage: 1,
          totalPage: 0,
        },
      };

    default:
      return state;
  }
};
