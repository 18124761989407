import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CrossIcon from "../../assets/icons/crossIcon.svg";
import DropDown from "../../components/generic/DropDown/DropDown";
import { declineReasonOptions } from "../../helpers/enums";
import { Info } from "@mui/icons-material";
import * as yup from "yup"; // Yup for validation
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams } from "react-router-dom";
import axios from "../../store/api/axios";
import { getSalesQuoteDetailAPI } from "../../store/action/salesQuote";
import { postDeclineAllQuoteShared } from "../../store/api";
import { useDispatch, useSelector } from "react-redux";
import { message } from "../../components/generic/SnakeAlert/SnakeAlert";

const SalesDeclineAllModal = () => {
  const style = {
    position: "absolute",
    top: "46%",
    left: "47%",
    transform: "translate(-50%, -50%)",
    width: 750,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "16px",
    height: "310px",
  };

  const [open, setOpen] = React.useState(false);
  const [isDeclinedSuccess, setIsDeclinedSuccess] = React.useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.quote.quoteDetails);
  const offlineQuotes = data?.data?.offlineQuotes || [];

  // const quoteUrl = offlineQuotes?.some(
  //   (item) => item.quoteStatus === quoteStatusList.CHANGE_REQUESTED,
  // );

  const handleViewAllQuotesButton = () => {
    let downloadLinks = offlineQuotes
      ?.map((item) => item?.quoteUrl)
      .filter((item) => item !== undefined && item !== null && item !== "");

    downloadLinks.forEach((link, index) => {
      setTimeout(() => {
        let downloadAnchor = document.createElement("a");
        downloadAnchor.href = link;
        downloadAnchor.target = "_blank";
        downloadAnchor.rel = "noopener noreferrer"; // Prevents security risks
        downloadAnchor.click();
      }, index * 500); // Add a 500ms delay for each click to avoid browser blocking
    });
  };

  // Yup validation schema
  const declineQuoteSchema = yup.object().shape({
    declineReason: yup.string().required("Decline reason is required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(declineQuoteSchema),
    defaultValues: {
      declineReason: "",
    },
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setIsDeclinedSuccess(false); // Reset the success state when closing
  };

  // Handle form submission
  const onSubmit = async (formData) => {
    try {
      const apiData = {
        quoteId: id,
        declineReason: formData.declineReason,
      };

      const response = await axios.post(postDeclineAllQuoteShared, apiData);

      if (response?.data?.success) {
        message.success(response?.data.msg);
        const quoteDetailsResponse = await dispatch(getSalesQuoteDetailAPI(id));

        if (quoteDetailsResponse?.data?.success) {
          setIsDeclinedSuccess(true);
          message.success(response?.data.msg);
          reset();
          setOpen(false);
        }
      } else {
        message.error(response?.data.msg);
      }
    } catch (error) {
      message.error(error);
    }
  };

  return (
    <>
      {!isDeclinedSuccess ? (
        <>
          <Typography
            component={"div"}
            sx={{
              display: "flex",
              justifyContent: "end",
              gap: "16px",
              alignItems: "center",
              flexDirection: "row-reverse",
              marginRight: "20px",
              marginTop: "10px",
            }}
          >
            <Typography
              component={"button"}
              onClick={handleOpen}
              sx={{
                fontSize: "14px",
                color: "#df0000",
                fontWeight: "500",
                cursor: "pointer",
              }}
            >
              Decline All
            </Typography>

            <Typography
              component={"div"}
              sx={{
                fontSize: "14px",
                color: "#0691fc",
                fontWeight: "500",
                cursor: "pointer",
              }}
              onClick={handleViewAllQuotesButton}
            >
              View All Quotes
            </Typography>
          </Typography>

          <Modal
            open={open}
            // onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography
                component={"div"}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  sx={{ fontSize: "20px", color: "black", fontWeight: "500" }}
                >
                  Decline All Quotes
                </Typography>
                <Button
                  sx={{ position: "absolute", top: "6px", right: "-6px" }}
                  onClick={handleClose}
                >
                  <img src={CrossIcon} alt="crossicon" />
                </Button>
              </Typography>

              <Typography
                sx={{
                  mt: 3,
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                }}
              >
                <Typography component={"div"}>
                  <Controller
                    name="declineReason"
                    control={control}
                    render={({ field }) => (
                      <DropDown
                        placeholder="Select Reason"
                        value={field.value}
                        onChange={(event) => field.onChange(event.target.value)}
                        data={declineReasonOptions.map(
                          (option) => option.value,
                        )}
                        style={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "gray",
                        }}
                      />
                    )}
                  />
                  {errors.declineReason && (
                    <Typography
                      sx={{
                        color: "red",
                        fontSize: "12px",
                        marginLeft: "12px",
                        marginTop: "6px",
                      }}
                    >
                      {errors.declineReason.message}
                    </Typography>
                  )}
                </Typography>

                <Typography
                  component={"div"}
                  sx={{
                    fontSize: "12px",
                    backgroundColor: "#fcefe8",
                    width: "400px",
                    textAlign: "center",
                    margin: "auto",
                    fontWeight: "500",
                    padding: "8px 16px",
                    borderRadius: "8px",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "10px",
                    color: "#d79006",
                  }}
                >
                  <Info size={12} /> If you decline all quotes, then this case
                  will be closed.
                </Typography>
              </Typography>

              <Typography
                component={"div"}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <Button
                  variant="outlined"
                  color="error"
                  sx={{
                    width: "155px",
                    height: "54px",
                    border: "1px solid #b3b3b3",
                    color: "gray",
                    borderRadius: "8px",
                    fontSize: "14px",
                    fontWeight: "500",
                    "&:hover": {
                      color: "red",
                      boxShadow: "none",
                    },
                  }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  variant="none"
                  disableRipple
                  sx={{
                    color: "white",
                    backgroundColor: "red",
                    borderRadius: "8px",
                    width: "155px",
                    height: "54px",
                    "&:hover": {
                      backgroundColor: "red",
                      boxShadow: "none",
                    },
                  }}
                  onClick={handleSubmit(onSubmit)}
                >
                  Decline
                </Button>
              </Typography>
            </Box>
          </Modal>
        </>
      ) : (
        <Typography variant="h5">Showing Lottie Animation...</Typography>
      )}
    </>
  );
};

export default SalesDeclineAllModal;
